import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "คู่มือการเริ่มเล่น"
    }}>{`คู่มือการเริ่มเล่น`}</h1>
    <hr></hr>
    <h2 {...{
      "id": "เชื่อมต่อกระเป๋ากับเกม"
    }}>{`เชื่อมต่อกระเป๋ากับเกม`}</h2>
    <p>{`เชื่อมต่อกระเป๋าคริปโตของคุณเข้ากับเกม ที่หน้าแดชบอร์ด`}</p>
    <p><img alt="guide" src={require("./public/images/guide-1.png")} /></p>
    <hr></hr>
    <h2 {...{
      "id": "ซื้อเหรียญ-clc-จาก-uniswap"
    }}>{`ซื้อเหรียญ CLC จาก Uniswap`}</h2>
    <p>{`หลังจากที่เชื่อมต่อกระเป๋าของคุณแล้ว จะสามารถซื้อเหรียญ CLC ของเราได้ที่หน้าแดชบอร์ดเลย`}</p>
    <p><img alt="guide" src={require("./public/images/guide-2.png")} /></p>
    <p>{`ปุ่มซื้อจากหน้าแดชบอร์ดของเรา จะทำการวาง CLC ไว้ด้านล่างให้อยู่แล้ว สามารถเลือกเหรียญเพื่อทำการแลกเปลี่ยนได้เลย`}</p>
    <p><img alt="guide" src={require("./public/images/uniswap.png")} /></p>
    <p>{`การซื้อเหรียญ CLC คุณจะต้องใช้เหรียญสกุลต่าง ๆ ใน Polygon ในการซื้อ โดยสามารถดูอัตราแลกเปลี่ยนค่าเงินได้ โดยการลองวางเหรียญสกุลอื่น ตามจำนวนที่คุณต้องการไว้ด้านบน เพื่อดูว่าจะได้ CLC
มาจำนวนเท่าไร`}</p>
    <p>{`เมื่อทำการแลกเปลี่ยนสำเร็จแล้ว สามารถตรวจสอบจำนวนเหรียญ CLC ได้จากกระเป๋าคริปโตของคุณ หรือตรวจสอบจากหน้าแดชบอร์ดก็ได้ `}</p>
    <p><img alt="howtostart" src={require("./public/images/white.png")} /></p>
    <blockquote>
      <p parentName="blockquote">{`หากการแลกเปลี่ยนสำเร็จ แต่เหรียญไม่ขึ้น ให้รอสักครู่ หรือลองรีเฟรชหน้าแดชบอร์ดหรือกระเป๋าคริปโตของคุณ`}</p>
    </blockquote>
    <hr></hr>
    <h2 {...{
      "id": "การฝากเหรียญ-clc-เข้าเกม"
    }}>{`การฝากเหรียญ CLC เข้าเกม`}</h2>
    <p>{`สามารถฝากเหรียญ CLC จากกระเป๋าของคุณเข้าไปในเกมได้ ที่หน้าแดชบอร์ด`}</p>
    <p><img alt="guide" src={require("./public/images/guide-5.png")} /></p>
    <h2 {...{
      "id": "ถอนเหรียญ-clc"
    }}>{`ถอนเหรียญ CLC`}</h2>
    <p>{`สามารถถอนเหรียญ CLC จากเกมไปที่กระเป๋าของคุณได้ ที่หน้าแดชบอร์ด`}</p>
    <p><img alt="guide" src={require("./public/images/guide-6.png")} /></p>
    <blockquote>
      <h4 parentName="blockquote" {...{
        "id": "การฝากและการถอน-มีอธิบายรายละเอียดอยู่ที่หน้า-การสร้างรายได้จากการเล่น"
      }}>{`การฝากและการถอน มีอธิบายรายละเอียดอยู่ที่หน้า `}<a parentName="h4" {...{
          "href": "https://doc.season.town/play-and-earn-th"
        }}>{`การสร้างรายได้จากการเล่น`}</a></h4>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      